import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import ButtonAuth from '../../components/ButtonAuth';
import errorMessages from '../../utils/errorMessages';
import useDeviceType from '../../utils/useDeviceType';

import './styles.css';
import {validateToken} from "../../api/api";

type errorType = {
    field: string;
    message: string;
};

type OrganizationFields = {
    organization: string;
};

const OrganizationSelector = () => {
    const deviceType = useDeviceType();

    const [isOrgSet, setIsOrgSet] = useState(false);
    const [recordId, setRecordId] = useState<string | null>(null);
    const [isError, setIsError] = useState<errorType>({ field: '', message: '' });
    const [organization, setOrganization] = useState<OrganizationFields>({ organization: '' });

    const pb = new PocketBase('https://auth.dillo.ar/');

    async function refreshToken() {
        const auth = localStorage.getItem('pocketbase_auth');

        const jsonAuth = JSON.parse(auth);

        // Validate token
        await validateToken(jsonAuth.token)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem('pocketbase_auth', JSON.stringify(res));
                    setIsOrgSet(Boolean(res?.record?.organization)); // Set org based on updated response
                    setRecordId(res?.record?.id || null);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Effect to check if organization is already set and to get the record ID
    useEffect(() => {
        refreshToken();
    }, []);

    // Handle organization submission
    const handleOnOrganizationClick = async () => {

        try {
            await pb.collection('users').update(recordId, {
                organization: organization.organization.toLowerCase(),
            });

            // Trigger state update to cause re-render and redirect
            setIsOrgSet(true);

            // Reset error state
            setIsError({ field: '', message: '' });

            const auth = localStorage.getItem('pocketbase_auth');

            const jsonAuth = JSON.parse(auth);

            const data = {
                "relation": recordId,
                "abecedario": 0,
                "abecedario_palabras": 0,
                "meses": 0,
                "vocabulario": 0,
                "email": jsonAuth.record.email
            };

            await pb.collection('statistics').create(data);

            await refreshToken();

            // Navigate to the home page or GameSelector
            location.reload()
        } catch (error) {
            console.log(error.data?.data || error);
            const errorData = error.data?.data;
            if (errorData?.organization) {
                setIsError({ field: 'organization', message: errorData.organization.code });
            } else {
                setIsError({ field: 'organization', message: 'validation_invalid_value' });
            }
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrganization({ ...organization, [e.target.name]: e.target.value });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleOnOrganizationClick();
        }
    };

    // If organization is already set, we don't need to render the form
    if (isOrgSet){
        location.reload();
        return
    }

    return (
        <div className='signin-container'>
            <div className='logo-container'>
                <img src="/dillo-brand.png" width='192px' alt="Dillo Brand" />
            </div>

            <h1 className="signin-title">Ingresa el código de institución</h1>
            <div className='form-container'>
                <div className={`${deviceType !== 'mobile' ? 'info-container-desktop' : 'info-container'}`}>
                    <Input
                        isError={isError.field === 'organization'}
                        type='text'
                        name='organization'
                        handleOnChange={handleOnChange}
                        value={organization.organization}
                        label='Código de institución'
                        handleKeyDown={handleKeyDown}
                        isRequired
                    />
                    <ButtonAuth
                        handleOnClick={handleOnOrganizationClick}
                        buttonText='Ingresar'
                    />
                    {isError.message && (
                        <label className="error-message">
                            {errorMessages[isError.message]}
                        </label>
                    )}
                </div>
                <label style={{ textAlign: "center" }}>
                    ¿No tienes código? <br />
                    Puedes solicitarlo a las autoridades de tu institución. <br />
                    Si tienes dudas o consultas, no dudes en <a href='https://dillo.ai/#contacto' style={{ color: '#7FCFF5', textDecoration: 'none' }}>contactarnos</a>
                </label>
            </div>
        </div>
    );
};

export default OrganizationSelector;
