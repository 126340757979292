import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useDeviceType from '../../utils/useDeviceType';
import './styles.css';
import MenuModal from "../../components/MenuModal";
import { Alert, Snackbar } from "@mui/material";
import DatePickerComponent from "../../components/DatePicker";

const Profile = () => {
    const deviceType = useDeviceType();

    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const pb = new PocketBase('https://auth.dillo.ar/');

    const LocalStorageData = JSON.parse(localStorage.getItem('pocketbase_auth'));
    const userId = LocalStorageData?.record?.id;

    const [profile, setProfile] = useState({
        name: LocalStorageData?.record?.name || '',
        last_name: LocalStorageData?.record?.last_name || '',
        email: LocalStorageData?.record?.email || '',
        birthdate: LocalStorageData?.record?.birthdate || '',
        organization: LocalStorageData?.record?.organization || '',
        disability: LocalStorageData?.record?.disability || false,
        type_of_disability: LocalStorageData?.record?.type_of_disability || '',
    });

    const validateForm = () => {
        const requiredFields = ['name', 'last_name', 'email', 'birthdate', 'organization'];
        for (let field of requiredFields) {
            if (!profile[field]) {
                return false;
            }
        }
        if (profile.birthdate === 'Invalid Date') {
            return false;
        }
        if (profile.disability === true && !profile.type_of_disability) {
            return false;
        }
        return true;
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            [name]: name === 'disability' ? value === 'true' : value,
        });
    };

    const handleOnSaveClick = async () => {
        if (validateForm()) {
            try {
                await pb.collection('users').update(userId, profile);
                setSuccess(true);
                setIsError(false);
            } catch (error) {
                console.log(error);
                setIsError(true);
            }
        } else {
            setIsError(true);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnSaveClick();
        }
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };

    return (
        <div className='profile-container'>
            <MenuModal/>
            <div className='brand-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                        style={{marginTop: "3em"}}
                    />
                </a>
            </div>
            <div className='profile-title-container'>
                <h1 className="title">Mi perfil</h1>
            </div>
            <div className={deviceType !== 'mobile' ? 'info-container-profile-desktop' : 'info-container-profile'}>
                <div className="profile-input">
                    <TextField
                        type='text'
                        name='name'
                        onChange={handleOnChange}
                        value={profile.name}
                        label='Nombre'
                        onKeyDown={handleKeyDown}
                        error={isError && !profile.name}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="profile-input">
                    <TextField
                        type='text'
                        name='last_name'
                        onChange={handleOnChange}
                        value={profile.last_name}
                        label='Apellido'
                        onKeyDown={handleKeyDown}
                        error={isError && !profile.last_name}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="profile-input">
                    <TextField
                        type='email'
                        name='email'
                        onChange={handleOnChange}
                        value={profile.email}
                        label='Correo electrónico'
                        onKeyDown={handleKeyDown}
                        error={isError && !profile.email}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="profile-input">
                    <DatePickerComponent
                        name='birthdate'
                        value={profile.birthdate}
                        onChange={handleOnChange}
                        label='Fecha de nacimiento'
                        handleKeyDown={handleKeyDown}
                        isRequired={true}
                        isError={false}
                    />
                </div>
                <div className="profile-input">
                    <TextField
                        type='text'
                        name='organization'
                        onChange={handleOnChange}
                        value={profile.organization}
                        label='Código de institución'
                        onKeyDown={handleKeyDown}
                        error={isError && !profile.organization}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="profile-input">
                    <TextField
                        select
                        name='disability'
                        value={profile.disability ? 'true' : 'false'}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                        label="Discapacidad"
                        error={isError && profile.disability === ''}
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        <option value="false">No</option>
                        <option value="true">Sí</option>
                    </TextField>
                </div>
                {profile.disability === true && (
                    <div className="profile-input disability-field">
                        <TextField
                            type='text'
                            name='type_of_disability'
                            onChange={handleOnChange}
                            value={profile.type_of_disability}
                            label='Tipo de discapacidad'
                            onKeyDown={handleKeyDown}
                            error={isError && !profile.type_of_disability}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="profile-button">
                <Button
                    style={{
                        background: '#7FCFF5',
                        textTransform: 'none',
                        fontFamily: '"MuseoSlab", "Roboto", sans-serif',
                        fontWeight: 'bold',
                        margin:'1em'
                    }}
                    variant="contained"
                    onClick={handleOnSaveClick}
                    fullWidth
                >
                    Guardar cambios
                </Button>
            </div>
            {isError &&
                <label className="error-message-profile">Por favor, completa todos los campos obligatorios.</label>}
            <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Tus datos han sido guardados correctamente.
                </Alert>
            </Snackbar>
            <p style={{margin: '2em', textAlign:'center'}}>Si has olvidado tu contraseña has click <a style={{color: '#7FCFF5'}}
                                                                                  href="/restablecer-contraseña"> aquí</a>
            </p>

        </div>
    );
};

export default Profile;