import { Grid } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


import "./styles.css"
import { useEffect, useState } from "react";

type CountdownProps = {
    showCountdown: boolean,
    isPlaying: boolean,
    setShowCountdown: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
    snackbarOpen: {
        open: boolean;
        message: string;
        severity: string;
    }
    currentWord?: string;
    currentIndex?: number
}

const Countdown = ({ isPlaying, setShowCountdown, setIsPlaying, snackbarOpen, currentWord, currentIndex}) => {

    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        // Display "genial" or "ups" for 2 seconds when snackbarOpen changes
        if (snackbarOpen.severity === "success" || snackbarOpen.severity === "error") {
        setShowImage(true);
        const timeoutId = setTimeout(() => {
            setShowImage(false);
            setShowCountdown(false);
            setIsPlaying(false);
        }, 2000);

        return () => {
            clearTimeout(timeoutId);
        };
        }

        if (snackbarOpen.severity === "success-word" || snackbarOpen.severity === "error-word") {
            setShowImage(true);
            const timeoutWordId = setTimeout(() => {
                setShowImage(false);
                setShowCountdown(false);
                setIsPlaying(false);
            }, 3000);

            return () => {
                clearTimeout(timeoutWordId);
            };
        }
    }, [snackbarOpen]);

    const getElement = () => {
        switch(snackbarOpen.severity) {
            case 'success':
                return getImage('genial')
            case 'success-alphabet':
                return getNextWordSplash("")
            case 'success-word':
                return getNextWordSplash("genial")
            case 'error-word':
                return getNextWordSplash("ups")
            case 'error':
                return getImage('ups')
            default:
                return (
                    <CountdownCircleTimer
                        isPlaying={isPlaying}
                        duration={3}
                        colors={["#7371EF", "#7573CF"]}
                        colorsTime={[2, 1]}
                        onComplete={() => {
                            setShowCountdown(false);
                            setIsPlaying(false);
                            return { shouldRepeat: false, delay: 1 };
                        }}
                    >
                        {renderTime}
                    </CountdownCircleTimer>
                )
        }
    }

    const getNextWordSplash = (imageType) => {
        return(
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100dvw',
                height: '100dvh',
                backgroundColor: 'white',
                color: 'black',
                opacity: '1'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {imageType && (
                        <img
                            src={`/${imageType}-badge.png`}
                            alt={imageType}
                            style={{width: '70%', height: 'auto'}}
                        />
                    )}
                    <span className="countdown-span-word-number"  >
            Palabra {currentIndex + 1}:
        </span>
                    <span className="countdown-span-word">
            {currentWord.toUpperCase()}
        </span>
                    <div style={{ transform: 'scale(0.70)', marginTop: '-1rem' }}>
                        <CountdownCircleTimer
                            isPlaying={isPlaying}
                            duration={3}
                            colors={["#7FCFF5", "#7FCFF5"]}
                            colorsTime={[2, 1]}
                            onComplete={() => {
                                setShowCountdown(false);
                                setIsPlaying(false);
                                return { shouldRepeat: false, delay: 1 };
                            }}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                </div>
            </div>
        )
    }

    const getImage = imageType => {
        return (
            <img
                src={`/${imageType}-badge.png`}
                alt={imageType}
                className="img-badge"
            />
        )
    }

    const renderTime = ({ remainingTime }) => {
        return(
            <div className="timer">
                {remainingTime !== 0 ? 
                    (<div className="value" style={{fontSize: '5rem'}}>
                        <img 
                            src={`/countdown/countdown-${remainingTime}.svg`}
                            alt={remainingTime}
                            style={{ width: '90px', height: '90px' }}
                        />
                    </div>) :
                    "too late"
                }
            </div>
        )
    };

    return(
        <div  
            className={`countdown-container 
                ${ snackbarOpen.severity === 'success' || snackbarOpen.severity === 'error' ? 
                    'transparent-background' : 'violet-background'}`} >
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh'}}
        >
            <Grid 
                container
                direction="column"
                justifyContent="center"
                alignItems="center"          
                item 
                xs={3}
                >  
                    <>
                        {showImage ? getElement() : null}
                        {/* {snackbarOpen.message && 
                            <span className='support-message'>{snackbarOpen.message}</span>
                        } */}
                    </>
            </Grid>
        </Grid>
    </div>
    )
}

export default Countdown