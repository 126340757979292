import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import "./styles.css"

function PasswordInput({ label, value, handleOnChange, handleKeyDown, name, isError = false, isRequired = false  }) {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
        <div className={`input-content ${isError && 'input-error'}`}>
            {label && (
                <label className="label-element" htmlFor={`input-${name}`}>
                    {label}
                    {isRequired && <span>*</span>}
                </label>
            )}
            <div className="password-input-wrapper">
                <input
                    className="input-element"
                    id={`input-${name}`}
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    onChange={handleOnChange}
                    value={value}
                    onKeyDown={handleKeyDown}
                />
                <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOffOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
                </button>
            </div>
        </div>
    );
}

export default PasswordInput;
