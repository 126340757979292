import React, { useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import PocketBase from 'pocketbase';
import errorMessages from '../../utils/errorMessages';
import'./styles.css'
import useDeviceType from "../../utils/useDeviceType.ts";
import ButtonAuth from "../../components/ButtonAuth";

const ConfirmEmail = () => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()
    const location = useLocation();
    const [isError, setIsError] = useState('');

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleConfirmEmail = async (event) => {

        if (event) event.preventDefault();

        try {
            await pb.collection('users').confirmVerification(token);

        } catch (error) {
            setIsError(errorMessages[error.data.message] || 'Error al verificar su correo');
        }
    };

    return (
        <div className='reset-container'>
            <div className='logo-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='192px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <h1 className="reset-title">Correo electrónico verificado</h1>
            <p className="reset-paragraph">
                {`Tu correo ha sido verificado
                con éxito.`}
            </p>
            <div className='form-reset-container'>
                <form
                    className={`${deviceType !== 'mobile' ? 'info-container-reset-desktop' : 'info-container-reset'}`}
                >
                    <ButtonAuth
                        buttonText="Volver"
                        handleOnClick={() => navigate('/')}
                    />
                </form>
            </div>
        </div>
    );
};

export default ConfirmEmail;
