import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import ButtonAuth from '../../components/ButtonAuth';
import errorMessages from '../../utils/errorMessages';
import useDeviceType from '../../utils/useDeviceType';
import'./styles.css'
import {useNavigate} from "react-router-dom";
import SuccessModal from "../../components/SuccessModal";

const RequestPasswordReset = () => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleRequestReset = async () => {
        try {
            await pb.collection('users').requestPasswordReset(email);
            setOpenModal(true);

        } catch (error) {
            setIsError(errorMessages[error.data.message] || 'Error al enviar el correo de restablecimiento');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRequestReset()
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        navigate('/');
    };

    return (
    <div className='reset-container'>
        <div className='logo-container'>
            <a  href="/">
            <img
                src="/dillo-brand.png"
                width='192px'
                alt='Dillo'
            />
            </a>
        </div>
        <div>
            <h1 className="reset-title">Restablecer contraseña</h1>
            <p className="reset-paragraph">
                {`Escribe tu dirección de correo electrónico
                 y te enviaremos las instrucciones
                 para restablecer la contraseña.`}
            </p>
        </div>
        <div className='form-reset-container'>
                <div className={`${deviceType !== 'mobile' ? 'info-container-reset-desktop' : 'info-container-reset'}`}>
                <Input
                    type='email'
                    name='email'
                    handleOnChange={(e) => setEmail(e.target.value)}
                    value={email}
                    label='Correo electrónico'
                    isRequired handleKeyDown={handleKeyDown}/>
                <ButtonAuth
                    handleOnClick={handleRequestReset}
                    buttonText='Enviar correo'
                />
                {isError && <label className="error-message">{isError}</label>}
            </div>
            </div>
        <SuccessModal open={openModal} onClose={handleCloseModal} title="¡Revise su email!" message="Por favor, revise la casilla de correo electrónico, allí encontrará instrucciones para restablecer su contraseña." />
    </div>
    );
};

export default RequestPasswordReset;
