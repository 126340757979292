import {useEffect, useRef} from 'react';
import {Camera} from '@mediapipe/camera_utils';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {Hands, HAND_CONNECTIONS} from '@mediapipe/hands';
import {predictAlphabetApi} from "../../api/api";
import {FRAMES_LENGTH_FOR_PREDICT_ALPHABET} from "../../../constants";
import {FilesetResolver, HandLandmarker} from '@mediapipe/tasks-vision';

let maxVideoWidth = 960;
let maxVideoHeight = 540;

let attempts = 0
let recordedResults: any[] = [];
let recordedLetters: any[] = [];

function alphabetDetectionProcess({
    isDialogOpen,
    selectedLetterRef,
    showLandmarks,
    setSnackbar,
    isPlayingRef,
    setIsPlaying,
    setShowCountdown,
    setChangeCurrentWord,
    setCurrentLetter,
    setCurrentLetterIndex,
    setIsLoading,
    setErrorModal
}) {

    useEffect(() => {
        const initialize = async () => {
            setIsLoading(true);
            await createHandLandmarker();
            await initCamera();
        };

        initialize().then(r => setIsLoading(false));

        return () => {
            //@ts-ignore
            const tracks = videoElement.current?.srcObject?.getTracks();
            tracks?.forEach(track => track.stop());
            //@ts-ignore
            camera.current?.close();
        };
    }, []);

    const createHandLandmarker = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.14/wasm"
            );
            // @ts-ignore
            camera.current = await HandLandmarker.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/latest/hand_landmarker.task`,
                    delegate: "GPU",
                },
                runningMode: "VIDEO",
                numHands: 2,
            });
        } catch (error) {
            console.error('Failed to create HandLandmarker:', error);
            setErrorModal(true);
        }
    };

    const initCamera = async () => {
        if (!videoElement.current || !camera.current) return;

        const constraintSets = [
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 800 },
                    height: { ideal: 600 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 854 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: true
            }
        ];

        for (const constraints of constraintSets) {
            try {
                videoElement.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.current.addEventListener("loadeddata", predictWebcam);
                console.log('Camera initialized with constraints:', constraints);

                // @ts-ignore
                maxVideoWidth = constraints.video.width.ideal
                // @ts-ignore
                maxVideoHeight  = constraints.video.height.ideal
                return; // If successful, exit the function
            } catch (error) {
                console.warn('Failed to initialize camera with constraints:', constraints, error);
                // Continue to the next set of constraints
            }
        }

        console.error('Failed to initialize camera with any of the provided constraints.');
        setErrorModal(true);
    };

    const videoElement = useRef<HTMLVideoElement | null>(null);
    const hands = useRef<Hands | null>(null);
    const camera = useRef<Camera | null>(null);
    const canvasEl = useRef<HTMLCanvasElement | null>(null);

    const isProcessingRef = useRef(false);

    const getProgress = () => {
        return recordedResults.length;
    };

    const resetProgress = () => {
        recordedResults = [];
        recordedLetters = [];
    };

    const showLandmarksRef = useRef(showLandmarks);

    useEffect(() => {
        showLandmarksRef.current = showLandmarks;
    }, [showLandmarks]);

    async function predictWebcam() {
        const canvas = canvasEl.current;
        if (!canvas || !videoElement.current || !camera.current) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const videoWidth = videoElement.current.videoWidth;
        const videoHeight = videoElement.current.videoHeight;

        canvas.style.width = videoWidth + "px";
        canvas.style.height = videoHeight + "px";
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const startTimeMs = performance.now();
        //@ts-ignore
        const results = await camera.current.detectForVideo(videoElement.current, startTimeMs);

        // Create a temporary canvas to store the current video frame
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = videoWidth;
        tempCanvas.height = videoHeight;
        const tempCtx = tempCanvas.getContext('2d');
        if (tempCtx) {
            tempCtx.drawImage(videoElement.current, 0, 0, videoWidth, videoHeight);
            //@ts-ignore
            results.image = tempCanvas;
        }

        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (results.landmarks) {
            if (screen.width >= 1280){
                for (const landmarks of results.landmarks) {
                    drawConnectors(ctx, landmarks, HAND_CONNECTIONS, {
                        color: "white",
                        lineWidth: 1
                    });
                    drawLandmarks(ctx, landmarks, { color: "#7FCFF5", lineWidth: 1, radius: 4 });
                }
            }
            // Store the frame as an image in results
            if (!isDialogOpen.current && !isProcessingRef.current && !isPlayingRef.current) {
                if (results.landmarks.length && results.handednesses.length)
                await processLandmarks(results);
            }
        }

        ctx.restore();

        if (videoElement.current?.srcObject) {
            window.requestAnimationFrame(predictWebcam);
        }
    }

    async function processLandmarks(results: any) {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;

        try {
            if (recordedResults.length < FRAMES_LENGTH_FOR_PREDICT_ALPHABET) {
                recordedResults.push(results);
            } else {
                let wordGame = selectedLetterRef.current;
                const yPred = await predictAlphabetApi(recordedResults);
                recordedResults = [];
                console.log(yPred);

                console.time('test');
                const currentLetterArray = Array.from(wordGame)[recordedLetters.length];

                if (currentLetterArray === undefined) {
                    resetGameState();
                    return;
                }

                const currentLetter = typeof currentLetterArray === 'string' ? currentLetterArray.toUpperCase() : '';

                if (yPred.letter) {
                    yPred.letter = mapLetter(yPred.letter, currentLetter);
                }

                if (yPred.letter.toUpperCase() === currentLetter) {
                    handleCorrectLetter(yPred.letter.toUpperCase(), wordGame);
                } else {
                    handleIncorrectLetter(currentLetter, wordGame);
                }
                console.timeEnd('test');
            }
        } catch (e) {
            console.error('Prediction failed', e);
            resetGameState();
        }

        isProcessingRef.current = false;
    }

    function resetGameState() {
        recordedLetters = [];
        setChangeCurrentWord(true);
        setIsPlaying(true);
        setShowCountdown(true);
        setCurrentLetterIndex(recordedLetters.length);
        attempts = 0;
    }

    function mapLetter(letter, currentLetter) {
        const letterMapping = {
            'No gesture recognized': '',
            'Failed': '',
            'V_2': 'V',
            'W_2': currentLetter === 'V' ? 'V' : 'W',
            'W': currentLetter === 'U' ? 'U' : 'W',
            'E': currentLetter === 'C' ? 'C' : 'E',
            'O': currentLetter === 'Q' ? 'Q' : 'O'
        };

        if (letterMapping[letter]) {
            setSnackbar({
                open: true,
                message: "No se ha podido reconocer, intentelo nuevamente",
                severity: "error"
            });
            return letterMapping[letter];
        }
        return letter;
    }

    function handleCorrectLetter(letter, wordGame) {
        recordedLetters.push(letter);
        const wordPredicted = recordedLetters.join('');
        const isWordComplete = wordPredicted.length === wordGame.length;

        setSnackbar({
            open: true,
            message: isWordComplete ? `Has completado la palabra!` : "Ahora la siguiente letra!",
            severity: isWordComplete ? "success-word" : "success"
        });

        if (isWordComplete) {
            resetGameState();
        } else {
            updateGameState(wordGame);
        }
    }

    function handleIncorrectLetter(currentLetter, wordGame) {
        setSnackbar({
            open: true,
            message: `Intentalo de nuevo, no es la letra correcta. Letra: ${currentLetter}`,
            severity: "error"
        });

        attempts += 1;

        if (attempts >= 3) {
            recordedLetters.push('*');
            const wordPredicted = recordedLetters.join('');
            const isWordComplete = wordPredicted.length === wordGame.length;

            setSnackbar({
                open: true,
                message: isWordComplete ? `Sigue practicando, pasaremos a la siguiente palabra` : "Sigue practicando! pasaremos a la siguiente letra!",
                severity: isWordComplete ? "error-word" : "error"
            });

            if (isWordComplete) {
                resetGameState();
            } else {
                updateGameState(wordGame);
                attempts = 0;
            }
        } else {
            updateGameState(wordGame);
        }
    }

    function updateGameState(wordGame) {
        setIsPlaying(true);
        setShowCountdown(true);
        setCurrentLetter(Array.from(wordGame)[recordedLetters.length]);
        setCurrentLetterIndex(recordedLetters.length);
    }

    return {maxVideoHeight, maxVideoWidth, canvasEl, videoElement, getProgress, resetProgress};
}


export default alphabetDetectionProcess;
