import useDeviceType from "../../utils/useDeviceType";
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";

import './styles.css'
import MenuModal from "../../components/MenuModal";
import React from "react";

const StartView = ({redirectTo, text, loading=false}) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()

    return(
        <div className="start-game-element-container" >
            <MenuModal/>
            <div>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <div className="start-game-text">{text}</div>
            <div className={`start-game-button-container ${deviceType !== 'mobile' && 'start-game-desktop-container'}`}>
            { loading ?
                    <Loader /> :
                    <Button 
                        text='Jugar'
                        onClick={() => navigate(`${redirectTo}`)}
                        buttonType='primary '
                    /> 
                }
                <Button text='Volver' onClick={() => navigate('/')} buttonType={'secondary'} />
            </div>
        </div>
    )
}

export default StartView