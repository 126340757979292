import React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './styles.css';

const SuccessModal = ({ open, onClose, title, message  }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div className="modal-error-container">
                <h2 className="modal-error-title" id="modal-title">{title || 'Acción completada exitosamente'}</h2>
                <p className="modal-error-info" id="modal-description">{message}</p>
                <div className="btn-error-container">
                    <Button style={{ color: 'white' }} onClick={onClose}>Aceptar</Button>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessModal;