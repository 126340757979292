import React, {useEffect, useState, useRef} from "react";
import './styles.css';
import {fetchWordsComplete} from "../utils/fetchWords";
import Countdown from "../components/Countdown";
import EndView from "../views/EndView";
import {fetchVersion} from "../api/api";
import { useUpdateProgress } from "../utils/effects-utils/progressEffects";
import { FRAMES_LENGTH_FOR_PREDICT } from "../../constants";
import wordsDetectionProcess from "./hooks/wordsDetectionProcess";
import HeaderWords from "../components/HeaderWords";
import Loader from "../components/Loader";
import ErrorModal from "../components/Modal";
import useDeviceType from "../utils/useDeviceType.ts";


function GameWordsHimno() {
    const isModalOpenRef = useRef(false);
    const [showLandmarks, setShowLandmarks] = useState(window.innerWidth >= 1280);
    const [ currentPosition, setCurrentPosition ] = useState<number>(0);
    const [selectedWord, setSelectedWord] = useState<string | null>('');
    const selectedWordRef = useRef(selectedWord);
    const currentFramesLengthForPredict = useRef(FRAMES_LENGTH_FOR_PREDICT);
    const [isPlaying, setIsPlaying] = useState(false);
    const isPlayingRef = useRef(isPlaying);
    const [showCountdown, setShowCountdown] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [ wordsList, setWordsList] = useState<string[]>([])
    const [ startGame, setStartGame ] = useState<boolean>(true)
    const [ endGame, setEndGame ] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState({open: false, message: '', severity: ''});
    const [changeCurrentWord ,setChangeCurrentWord] = useState<boolean>(false)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [progress, setProgress] = useState(0);
    const deviceType = useDeviceType()

    const [errorModal, setErrorModal] = useState<boolean>(false);

    const handleOpenModal = () => {
        setErrorModal(true);
    };

    const handleCloseModal = () => {
        setErrorModal(false);
    };

    const [isLoadingLandmarks, setIsLoadingLandmarks] = useState<boolean>(false);
    const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false)

    useEffect(() => {
        async function callFetchVersion() {
            const isLoading = await fetchVersion();
            setLoading(isLoading);
        }

        async function fetchWords() {
            const wordListAPI =  await fetchWordsComplete(setIsLoadingApi);
            setWordsList(wordListAPI)
        }

        callFetchVersion();

        setWordsList( [
            'oid', 'mortales', 'el grito sagrado', 'libertad', 'oid el ruido', 'de rotas cadenas', 'desde el trono', 'a la noble', 'igualdad', 'dignisimo abrieron', 'las provincias', 'unidas del sur', 'los libres del mundo', 'responden', 'pueblo', 'argentino', 'salud', 'sean eternos', 'los laureles', 'que supimos conseguir', 'coronados', 'de gloria', 'vivamos', 'oh', 'juremos', 'gloria', 'morir'
        ])

    }, []);

    useEffect(() => {
        if(wordsList.length !== 0 && wordsList.length !== undefined){
            const word = wordsList[0]
            setSelectedWord(word)
            
        }
    },[wordsList])

    
    useEffect(() => {
        console.log("isPlaying state updated:", isPlaying);
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    useEffect(() => {
        if(wordsList.length !== 0 && wordsList.length !== undefined) {
            if(currentPosition < wordsList.length){
                const word = wordsList[currentPosition]
                setSelectedWord(word)
            } else {
                setSnackbarOpen({
                    open: true,
                    message: 'Ha finalizado el juego',
                    severity: 'finish-game'
                })
                setShowConfetti(true)
                setEndGame(true)
            }
        }
            
    }, [currentPosition])

    const {
        videoElement,
        maxVideoWidth,
        maxVideoHeight,
        canvasEl,
        getProgress,
        resetProgress,
    } = wordsDetectionProcess({
        isDialogOpen: isModalOpenRef,
        showLandmarks,
        setSnackbar: setSnackbarOpen,
        isPlayingRef,
        setIsPlaying,
        setShowCountdown,
        setChangeCurrentWord,
        selectedWord: selectedWordRef,
        setIsLoading: setIsLoadingLandmarks,
        setErrorModal
    });


    useUpdateProgress(getProgress, setProgress);

    useEffect(() => {
        selectedWordRef.current = selectedWord;
    }, [selectedWord]);

    useEffect (() => {
        if(changeCurrentWord) {
            setCurrentPosition(currentPosition + 1);
        }
    }, [changeCurrentWord])

    useEffect(() => {
        resetProgress();
        setChangeCurrentWord(false)
    }, [selectedWord])

    const getNextWord = () => {
        return wordsList[currentPosition + 1]
    }

    return (
        <div className="container">
            {!showCountdown && <HeaderWords wordsList={wordsList} currentWord={selectedWord.replace("desde el trono", "ved el trono")}/>}
            <video 
                className="video"
                playsInline
                autoPlay
                ref={videoElement}
                width={maxVideoWidth}
                height={maxVideoHeight}
            />
            {!endGame && <canvas className="video" ref={canvasEl} width={maxVideoWidth} height={maxVideoHeight}/>}
            {endGame && 
                <EndView
                    redirectTo='/himno'
                    text={<>Felicitaciones completaste<span> el himno</span></>}
                />
            }
            {showCountdown && (
                <Countdown 
                    isPlaying={isPlaying}
                    setShowCountdown={setShowCountdown}
                    setIsPlaying={setIsPlaying}
                    snackbarOpen={snackbarOpen}
                    currentWord={selectedWord}
                    currentIndex={currentPosition}/>
            )}
            {!showCountdown && (
                <div className='sign'>
                    <video style={{
                        maxHeight: deviceType === 'mobile' ? '60vh' : '40vh',
                        maxWidth: deviceType === 'mobile' ? '45vw' : '25vw',
                    }} src={`/video/${selectedWord.replace(/\s+/g, '-').toLowerCase()}.mp4`} playsInline autoPlay loop muted></video>
                </div>)
            }
            <div className="next-word"> {getNextWord()?.toUpperCase()}</div>
            <div className="progress-container"
                 style={{position: 'absolute', bottom: 0, width: '100%', zIndex: 3, height: '0.6em'}}>
                <div className="progress-bar"
                     style={{width: `${(progress / currentFramesLengthForPredict.current) * 100}%`}}></div>
            </div>
            {errorModal && <ErrorModal open={errorModal} handleClose={handleCloseModal} />}
            {
                !errorModal && (isLoadingLandmarks || isLoadingApi )&& <Loader />
            }
        </div>
    );
}

export default GameWordsHimno;
