import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../../utils/useDeviceType';
import PocketBase from 'pocketbase';
import MenuModal from '../../components/MenuModal';
import { Button } from '../../components/Button';

import './styles.css';

const GameSelector = () => {
    const navigate = useNavigate();
    const deviceType = useDeviceType();
    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleOnClick = (gameType) => {
        navigate(`/${gameType}`);
    }

    return (
        <div className='selector-container'>
            <MenuModal />
            <div className='brand-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <label className='selector-text'>Elige la modalidad de juego para aprender lengua de señas</label>
            <div className={`btn-selector-container ${deviceType !== 'mobile' && 'desktop-container'}`}>
                <Button
                    onClick={() => handleOnClick('abecedario')}
                    buttonType='primary'
                    text='Aprender el abecedario'
                />
                <Button
                    onClick={() => handleOnClick('abecedario-palabras')}
                    buttonType='primary'
                    text='Deletrear palabras'
                />
                <Button
                    onClick={() => handleOnClick('meses')}
                    buttonType='primary'
                    text='Aprender los meses del año'
                />
                <Button
                    onClick={() => handleOnClick('vocabulario')}
                    buttonType='primary'
                    text='Aprender vocabulario'
                />
                <Button
                    onClick={() => handleOnClick('himno')}
                    buttonType='primary'
                    text='Aprender himno'
                />
                <Button
                    onClick={() => handleOnClick('himno-video')}
                    buttonType='primary'
                    text='Ver himno completo'
                />
                {/*<Button*/}
                {/*    onClick={() => handleOnClick('frases')}*/}
                {/*    buttonType='primary'*/}
                {/*    text='Practicar frases'*/}
                {/*/>*/}
            </div>
        </div>
    );
};

export default GameSelector;
