import React, { useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import PocketBase from 'pocketbase';
import ButtonAuth from '../../components/ButtonAuth';
import errorMessages from '../../utils/errorMessages';
import useDeviceType from '../../utils/useDeviceType';
import'./styles.css'
import SuccessModal from "../../components/SuccessModal";
import PasswordInput from "../../components/PasswordInput";

const ConfirmPasswordReset = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const deviceType = useDeviceType()
    const [isError, setIsError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleConfirmReset = async (event) => {

        if (event) event.preventDefault();

        try {
            await pb.collection('users').confirmPasswordReset(token, password, passwordConfirm);
            setIsError(null);
            setOpenModal(true);

        } catch (error) {
            const code = error?.data?.data?.password?.code;
            setIsError(errorMessages[code || error?.data?.message] || 'Error al restablecer la contraseña');
            if (!code) setOpenModal(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleConfirmReset(e)
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        navigate('/');
    };

    return (
        <div className='reset-container'>
            <div className='logo-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='192px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <h1 className="reset-title">Confirmar cambio de contraseña</h1>
            <p className="reset-paragraph">
                {`Establezca una nueva contraseña a continuación 
                para cambiar su contraseña actual`}
            </p>
            <div className='form-reset-container'>
                <form
                    onSubmit={handleConfirmReset}
                    className={`${deviceType !== 'mobile' ? 'info-container-reset-desktop' : 'info-container-reset'}`}
                >
                    <PasswordInput
                        name='password'
                        handleOnChange={(e) => setPassword(e.target.value)}
                        value={password}
                        label='Nueva contraseña'
                        isRequired
                        handleKeyDown={handleKeyDown}
                    />
                    <PasswordInput
                        name='passwordConfirm'
                        handleOnChange={(e) => setPasswordConfirm(e.target.value)}
                        value={passwordConfirm}
                        label='Confirmar nueva contraseña'
                        isRequired
                        handleKeyDown={handleKeyDown}
                    />
                    <ButtonAuth
                        handleOnClick={handleConfirmReset}
                        buttonText='Restablecer contraseña'
                    />
                    {isError && <label className="error-message">{isError}</label>}
                </form>
            </div>
            <SuccessModal open={openModal} onClose={handleCloseModal} title="¡Contraseña cambiada!" message="Su contraseña se ha cambiado con éxito" />
        </div>
    );
};

export default ConfirmPasswordReset;
