import useDeviceType from "../../utils/useDeviceType";
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';

import './styles.css'
import MenuModal from "../../components/MenuModal";
import React from "react";

const ViewVideo = ({redirectTo, loading=false}) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType();

    return (
        <div className="youtube-element-container" >
            <MenuModal />
            <div>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                        style={{marginTop: '2em'}}
                    />
                </a>
            </div>
            <div className="youtube-video-container">
                <iframe
                    className="youtube-video"
                    src="https://www.youtube.com/embed/A85RzQLac1o"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <div className={`start-game-button-container ${deviceType !== 'mobile' && 'start-game-desktop-container'}`}>
                <Button text='Volver' onClick={() => navigate('/')} buttonType={'secondary'} />
            </div>
        </div>
    );
}

export default ViewVideo