import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import './styles.css'

const  StatisticsProgress = ({records}) => {
    const calculateProgressFraction = (key) => {
        const totalRecords = records.length;
        const completedRecords = records.filter(record => record[key] > 0).length;
        return `${completedRecords} / ${totalRecords}`
    }
    const calculateProgressPercentage = (key) => {
        const totalRecords = records.length;
        const completedRecords = records.filter(record => record[key] > 0).length;
        return (completedRecords / totalRecords) * 100
    }

    const games =  [
        {label:"Abecedario", key:"abecedario"},
        {label:"Abecedario palabras", key:"abecedario_palabras"},
        {label:"Meses", key:"meses"},
        {label:"Vocabulario", key:"vocabulario"},
        {label:"Himno", key:"himno"},
    ]
    const gaugeSettings = {
        width: 200,
        height: 200,
        cornerRadius: "50%",
    };

    // @ts-ignore
    return (
        <div className="game-progress">
            {games.map((game) => (
                <div key={game.key} className="arc-design">
                    <Gauge
                        {...gaugeSettings}
                        value={calculateProgressPercentage(game.key)}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 0,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: '#73BDE0F4',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                                fill: theme.palette.text.disabled,
                            },
                        })}
                    />
                    <div
                        className="gauge-text-overlay"
                    >
                        {calculateProgressFraction(game.key)}
                    </div>
                    <span className="arc-label">{game.label}</span>
                </div>
            ))}
        </div>
    );
};

export default StatisticsProgress;