import PocketBase from 'pocketbase';
import MenuModal from "../../components/MenuModal";
import './styles.css'
import React, {useEffect, useState} from "react";
import StatisticsTable from "../../components/StatisticsTable";
import StatisticsProgress from "../../components/StatisticsProgress";

const Dashboard = () => {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        async function sendStatistics() {
            const pb = new PocketBase('https://auth.dillo.ar');

            try {
                const records = await pb.collection('statistics').getFullList({
                    sort: '-created',
                });
                setRecords(records);
            } catch (error) {
            }
        };
        sendStatistics();
    }, []);

    return(
        <div className="dashboard-container">
            <MenuModal/>
            <div className='brand-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                        style={{marginTop: "3em"}}
                    />
                </a>
            </div>
            <StatisticsProgress records={records} />
            <StatisticsTable records={records} />
        </div>
    )
}

export default Dashboard